import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PostDefault from "./post/preview";
import axios from "axios";
import Blogs from "./blogs";
import PodcastList from "./media/audios";
import VideoList from "./media/videos";
import GoBtn from "../components/gobtn";
import loader from "../../src/img/loader.gif";
import avatar from "../../src/img/avatars/empty.png";
import ym from "react-yandex-metrika";

const Home = ({ url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [inputFocus, setInputFocus] = useState();
  const [searchIn, setSearchIn] = useState({ articles: true });
  const [searchList, setSearchList] = useState("articles");
  const [dataList, setDataList] = useState();
  const [avatarId, setAvatarId] = useState(1);

  const blogsRef = useRef();
  const podcastRef = useRef();
  const videoRef = useRef();
  const searchRef = useRef();
  let userId;

  useEffect(() => {
    window.Telegram?.WebApp?.CloudStorage?.getItem("JNJ-avatar", (kaka, id) =>
      setAvatarId(Number(id) || 1)
    );
  }, []);

  useEffect(() => {
    if (window.location.href.includes("tguser_data=")) {
      userId = window.location.href.split("tguser_data=")[1].split("__")[0];
    }
    if (userId && userId.includes("&")) {
      userId = userId.split("&")[0];
    }

    if (
      !localStorage.getItem("jnj-user-id") ||
      (userId && userId !== localStorage.getItem("jnj-user-id"))
    ) {
      localStorage.setItem("jnj-user-id", userId);
    }
  }, [userId]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/articles`)
      .then((res) => {
        setIsLoading(false);
        setPosts(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handleSearchList = (e) => {
    setSearchIn(false);
    setSearchList(e.target.id);
    setSearchIn((searchIn) => ({ ...searchIn, [e.target.id]: true }));
    e.target.classList.add("active");
    if (blogsRef.current === e.target) {
      podcastRef.current.classList.remove("active");
      videoRef.current.classList.remove("active");
    }
    if (podcastRef.current === e.target) {
      blogsRef.current.classList.remove("active");
      videoRef.current.classList.remove("active");
    }
    if (videoRef.current === e.target) {
      podcastRef.current.classList.remove("active");
      blogsRef.current.classList.remove("active");
    }
    axios
      .get(`${url}/api/webapp/${e.target.id}?search=${searchRef.current.value}`)
      .then((resp) => {
        if (resp.data.length > 0) {
          setSearchIn({ [e.target.id]: true });
          setDataList(resp.data);
        } else {
          setSearchIn({ empty: true });
        }
      });
  };

  const handleInput = () => {
    setInputFocus(true);
  };

  const searchFromInput = () => {
    axios
      .get(`${url}/api/webapp/${searchList}?search=${searchRef.current.value}`)
      .then((resp) => {
        if (resp.data.length > 0) {
          setSearchIn({ [searchList]: true });
          setDataList(resp.data);
        } else {
          setSearchIn({ empty: true });
        }
      });
    ym("reachGoal", "search");
  };

  const searchOnEnter = (event) => {
    if (event.key === "Enter") {
      axios
        .get(
          `${url}/api/webapp/${searchList}?search=${searchRef.current.value}`
        )
        .then((resp) => {
          if (resp.data.length > 0) {
            setSearchIn({ [searchList]: true });
            setDataList(resp.data);
          } else {
            setSearchIn({ empty: true });
          }
        });

      ym("reachGoal", "search");
    }
  };
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <>
          <div className="pl-2 pr-2 pt-2 mb-2">
            <Link
              to="/profile"
              className="flex align-center mb-2 avatar-link gap-2"
            >
              <div className="avatar">
                <img
                  src={require(`../img/avatars/avatar${avatarId}.svg`)}
                  alt="profile icon"
                />
              </div>
              <h1 className="avatar-link">Личный кабинет</h1>
            </Link>
            <label className="search-form">
              <input
                ref={searchRef}
                type="text"
                placeholder="Поиск"
                onChange={handleInput}
                onKeyDown={searchOnEnter}
              />
              <button onClick={searchFromInput}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M12.793 11.3034C12.6054 11.1154 12.3508 11.0098 12.0852 11.0098H11.71L11.43 10.7398C12.41 9.59977 13 8.11977 13 6.50977C13 2.91977 10.09 0.00976562 6.5 0.00976562C2.91 0.00976562 0 2.91977 0 6.50977C0 10.0998 2.91 13.0098 6.5 13.0098C8.11 13.0098 9.59 12.4198 10.73 11.4398L11 11.7198V12.095C11 12.3605 11.1056 12.6152 11.2936 12.8028L15.2929 16.7941C15.6835 17.1839 16.3162 17.1836 16.7064 16.7934L16.7836 16.7162C17.1738 16.3259 17.1742 15.6933 16.7843 15.3027L12.793 11.3034ZM6.5 11.0098C4.01 11.0098 2 8.99977 2 6.50977C2 4.01977 4.01 2.00977 6.5 2.00977C8.99 2.00977 11 4.01977 11 6.50977C11 8.99977 8.99 11.0098 6.5 11.0098Z"
                    fill="#858585"
                  />
                </svg>
              </button>
            </label>
          </div>
          {inputFocus ? (
            <>
              <span
                ref={blogsRef}
                id="articles"
                className="search-list ml-2 mr-2"
                onClick={(e) => handleSearchList(e)}
              >
                Статьи
              </span>
              <span
                ref={podcastRef}
                id="podcast"
                className="search-list mr-2"
                onClick={(e) => handleSearchList(e)}
              >
                Подкасты
              </span>
              <span
                ref={videoRef}
                id="video"
                className="search-list mr-2"
                onClick={(e) => handleSearchList(e)}
              >
                Видео
              </span>
              {searchIn.empty ? (
                <div className="media-wrapper-3">
                  <p className="media-main">Ничего не найдено</p>
                  <div className="mt-2 mb-2 pl-2 pr-2">
                    <GoBtn title="Вернуться на главную" refresh={true} />
                  </div>
                </div>
              ) : null}
              {searchIn.articles ? (
                <Blogs
                  navHead={false}
                  refresh={true}
                  dataList={dataList}
                  css={"posts-wrapper-1"}
                />
              ) : null}
              {searchIn.podcast ? (
                <PodcastList
                  navHead={false}
                  refresh={true}
                  dataList={dataList}
                  css={"media-wrapper-1"}
                />
              ) : null}
              {searchIn.video ? (
                <VideoList
                  navHead={false}
                  refresh={true}
                  dataList={dataList}
                  css={"media-wrapper-1"}
                />
              ) : null}
            </>
          ) : (
            <>
              <div className="flex align-center gap-2 mt-2 pl-2 pr-2">
                <Link
                  to="/prostate-analysis"
                  className="bg-link"
                  onClick={() => {
                    ym("reachGoal", "click_upsa");
                  }}
                >
                  Общая выживаемость при РПЖ
                </Link>
                <Link
                  to="/board"
                  className="bg-link"
                  onClick={() => {
                    ym("reachGoal", "click_clin_reco");
                  }}
                >
                  Клинические рекомендации в 7 кликов
                </Link>
              </div>
              <div className="flex flex-wrap justify-between gap-2 mt-2 pl-2 pr-2 home_block_01">
                <Link
                  to="/media"
                  className="btn-link btn-link-video"
                  onClick={() => {
                    ym("reachGoal", "click_video");
                  }}
                >
                  <div className="flex flex-column block_01">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          x="2"
                          y="4"
                          width="20"
                          height="16"
                          rx="2"
                          fill="white"
                        />
                        <path
                          d="M16.7 11.8268C16.8333 11.9038 16.8333 12.0962 16.7 12.1732L9.8 16.1569C9.66667 16.2339 9.5 16.1377 9.5 15.9837V8.01628C9.5 7.86232 9.66667 7.7661 9.8 7.84308L16.7 11.8268Z"
                          fill="#F22486"
                        />
                      </svg>
                    </div>
                    <div className="title_0">
                      Видео и <br /> подкасты
                    </div>
                  </div>
                </Link>
                <Link
                  to="/blogs"
                  className="btn-link btn-link-post"
                  onClick={() => {
                    ym("reachGoal", "click_articles");
                  }}
                >
                  <div className="flex flex-column block_01">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M3 4V17.5C3 18.9886 4.2114 20.2 5.7 20.2H18.3C19.7805 20.2 21 18.9805 21 17.5V7.6H19.2V17.5C19.2 18.0085 18.8085 18.4 18.3 18.4C17.8032 18.4 17.4 17.9959 17.4 17.5V4H3ZM6.6 6.7H13.8V9.4H6.6V6.7ZM6.6 12.1H13.8V13.9H6.6V12.1ZM6.6 15.7H13.8V17.5H6.6V15.7Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="title_0">Статьи</div>
                  </div>
                </Link>
                <Link
                  to="/meds"
                  className="btn-link btn-link-erleada"
                  onClick={() => {
                    ym("reachGoal", "click_prep");
                  }}
                >
                  <div className="flex flex-column block_01">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5.5645 10.8671L11.8671 4.5645C13.962 2.4785 17.3406 2.4785 19.4355 4.5645C21.5215 6.6505 21.5215 10.038 19.4355 12.1329L13.1329 18.4355C11.038 20.5215 7.6505 20.5215 5.5645 18.4355C3.4785 16.3406 3.4785 12.962 5.5645 10.8671ZM6.83036 12.1329C5.89434 13.06 5.58233 14.3883 5.90325 15.5739L11.2431 10.2431L15.0228 14.0228L18.1696 10.8671C19.5692 9.4764 19.5692 7.22103 18.1696 5.83036C16.779 4.43078 14.5236 4.43078 13.1329 5.83036L6.83036 12.1329Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="title_0">Препараты</div>
                  </div>
                </Link>
                <Link
                  to="/clinic"
                  className="btn-link btn-link-clinic"
                  onClick={() => {
                    ym("reachGoal", "click_clinic");
                  }}
                >
                  <div className="flex flex-column block_01">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6H11.1667L9 4H4Z"
                          fill="white"
                        />
                        <rect
                          x="11"
                          y="10"
                          width="2"
                          height="6"
                          fill="#29D7F9"
                        />
                        <rect
                          x="15"
                          y="12"
                          width="2"
                          height="6"
                          transform="rotate(90 15 12)"
                          fill="#29D7F9"
                        />
                      </svg>
                    </div>
                    <div className="title_0">
                      Клинические <br /> случаи
                    </div>
                  </div>
                </Link>
              </div>
              <div className="topical mt-2">
                <div className="topical-head flex align-center justify-between mb-2 pl-2 pr-2">
                  <h3>Статьи</h3>
                  <Link
                    to="/blogs"
                    className="link"
                    onClick={() => {
                      ym("reachGoal", "click_main_see_all");
                    }}
                  >
                    Смотреть все
                  </Link>
                </div>
                <div className="topical-post pb-4 pl-2">
                  {posts.map((item, index) => (
                    <PostDefault
                      header={item.header}
                      picture={
                        item.content.filter((el) =>
                          el.includes("backend/images")
                        )[0]
                      }
                      id={item.id}
                      key={index}
                      date={item.created_date}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
