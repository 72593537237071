import React, { useEffect } from "react";
import GoBtn from "../../components/gobtn";
import GoBtnArrow from "../../components/gobtnarrow";
import NavHead from "../../components/navhead";
import axios from "axios";

const ErleadaMgchrpj = ({ url }) => {
  useEffect(() => {
    axios.post(`${url}/api/user_activity/`, {
      user: window.Telegram.WebApp.initDataUnsafe.user.id,
      erleada_page: 1,
    });
  }, []);

  return (
    <div className="content_x__block">
      <NavHead title="мГЧРПЖ" />
      <div className="content_x mt-2 pl-2 pr-2">
        <div>
          <GoBtnArrow url="/erleada/mgchrpj/about" title="О заболевании" />
        </div>
        <div className="mt-2">
          <GoBtnArrow url="/erleada/mgchrpj/survival" title="Эффективность" />
        </div>
        <div className="mt-2">
          <GoBtnArrow
            url="/erleada/mgchrpj/result"
            title="Результаты исследования"
          />
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ErleadaMgchrpj;
