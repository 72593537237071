import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import loader from "../img/loader.gif";

import ProfileEditHeader from "./profile-edit/header";
import ProfileForm from "./profile-edit/form";
import axios from "axios";

const ProfileEdit = ({ url, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    axios
      .get(`${url}/api/webapp/user/${userId}`)
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  }, [userId, url]);

  if (isLoading || !profile)
    return <img src={loader} alt="loader" className="loader" />;

  return (
    <div className="profile-edit-container">
      <ProfileEditHeader />
      <ProfileForm initProfile={profile} url={url} userId={userId} />

      <div className="edit-cookies">
        <Link to="/profile/tof">
          <p>Условия использования и политика конфиденциальности</p>
        </Link>
        <Link to="/profile/cookie">
          <p>Политика COOKIES</p>
        </Link>
      </div>
    </div>
  );
};

export default ProfileEdit;
