import React, { useEffect, useState } from "react";

import edit from "../../img/edit.svg";
import { Link } from "react-router-dom";

import back from "../../img/back-arrow.svg";

const ProfileHeader = ({ fName, lName, spec, patronymic }) => {
  const [avatarId, setAvatarId] = useState(1);

  useEffect(() => {
    window.Telegram?.WebApp?.CloudStorage?.getItem("JNJ-avatar", (kaka, id) =>
      setAvatarId(Number(id) || 1)
    );
  }, []);

  return (
    <section className="profile-info">
      <Link to="/" className="back-button">
        <img src={back} alt="back" />
      </Link>
      <div className="profile-img">
        <img
          src={require(`../../img/avatars/avatar${avatarId}.svg`)}
          alt="avatar"
        />
      </div>
      <div className="profile-text">
        <h1>
          {lName} {fName} {patronymic}
        </h1>
        <p>{spec}</p>
      </div>
      <Link to="edit" className="profile-edit">
        <img src={edit} alt="edit" />
        <p>Редактировать</p>
      </Link>
    </section>
  );
};

export default ProfileHeader;
