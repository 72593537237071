import React, { useEffect, useState } from "react";

import ProfileHeader from "./profile/header";
import Achievements from "./profile/achievements";
import loader from "../img/loader.gif";

import Favourite from "./profile/favourite";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Profile = ({ url, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.Telegram?.WebApp?.BackButton.show();
    window.Telegram?.WebApp?.BackButton.onClick(() => navigate("/tap"));
    return () => window.Telegram?.WebApp?.BackButton.hide();
  }, [navigate]);

  useEffect(() => {
    axios
      .get(`${url}/api/webapp/user/${userId}`)
      .then((res) => {
        console.log(res);
        setProfile(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  }, [userId, url]);

  if (isLoading || !profile)
    return <img src={loader} alt="loader" className="loader" />;

  return (
    <div className="profile-container">
      <ProfileHeader
        fName={profile?.first_name}
        lName={profile?.last_name}
        patronymic={profile?.patronymic}
        spec={
          profile?.available_specializations?.find(
            (spec) => spec[0] === profile?.specialization
          )[1]
        }
      />
      <div className="profile-content">
        <Achievements  url={url} userId={userId} />
        <Favourite url={url} userId={userId} />
      </div>
    </div>
  );
};

export default Profile;
