import React, { useEffect, useState } from "react";

import drHouse from "../../img/profile/achievements/dr-house.svg";
import expertRMP from "../../img/profile/achievements/expert-rmp.svg";
import expertRPJ from "../../img/profile/achievements/expert-rpj.svg";
import maraphonPodcast from "../../img/profile/achievements/marathon-podcast.svg";
import topLevelAnalyst from "../../img/profile/achievements/top-level-analyst.svg";
import videoTourman from "../../img/profile/achievements/video-tourman.svg";
import lostConnoisseur from "../../img/profile/achievements/lost-connoisseur.svg";
import regularViewer from "../../img/profile/achievements/regular-viewer.svg";
import firstStep from "../../img/profile/achievements/first-step.svg";
import communityMouthpiece from "../../img/profile/achievements/community-mouthpiece.svg";
import loader from "../../img/loader.gif";

import closedAchievement from "../../img/achievement-closed.svg";
import axios from "axios";

const getAchievemntImage = (name) => {
  if (name === "Доктор Хаус 2.0") return drHouse;
  if (name === "Аналитик высшего уровня") return topLevelAnalyst;
  if (name === "Подкаст-марафонец") return maraphonPodcast;
  if (name === "Видеогурман") return videoTourman;
  if (name === "Знаток РПЖ") return expertRPJ;
  if (name === "Знаток РМП") return expertRMP;
  if (name === "Первый шаг") return firstStep;
  if (name === "Постоянный зритель") return regularViewer;
  if (name === "Заблудший знаток") return lostConnoisseur;
  if (name === "Рупор сообщества") return communityMouthpiece;

  return closedAchievement;
};

const Achievements = ({ url, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [achievements, setAchievement] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${url}/api/achievements/?user=${window.Telegram.WebApp.initDataUnsafe.user.id}`
      )
      .then((res) => {
        console.log(res.data);
        setAchievement(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  }, [userId, url]);

  if (isLoading) return <img src={loader} alt="loader" className="loader" />;

  return (
    <section className="profile-progress">
      <h2>Достижения</h2>
      <div className="achievements">
        {achievements
          .filter((ach) => ach.type !== "closed")
          .map((ach) => (
            <div className="achievement-item" key={ach.id}>
              <img
                src={
                  ach.is_completed
                    ? getAchievemntImage(ach.title)
                    : closedAchievement
                }
                alt="unlock"
              />
              <div className="achievement-text">
                <h3>{ach.title}</h3>
                <p>{ach.description}</p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default Achievements;
