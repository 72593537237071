import React, { useEffect, useState } from "react";

import favouriteImg from "../../img/profile/favourite.png";
import loader from "../../img/loader.gif";
import axios from "axios";

import favouriteActiveSvg from "../../img/favourite-active.svg";
import { Link } from "react-router-dom";

const Favourite = ({ url, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [favourite, setFavourite] = useState(null);
  const [fullFavourite, setFullFavourite] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${url}/api/favorites?user=${window.Telegram.WebApp.initDataUnsafe.user.id}`
      )
      .then((res) => {
        console.log(res.data);
        setFavourite(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  }, [userId, url]);

  useEffect(() => {
    if (!favourite) return;
    const videosIds = favourite
      .filter((f) => f._model === "Video")
      .map((f) => f.instance_id);
    const articleIds = favourite
      .filter((f) => f._model === "Article")
      .map((f) => f.instance_id);
    const podcastIds = favourite
      .filter((f) => f._model === "Podcast")
      .map((f) => f.instance_id);

    const handleReq = async () => {
      const art = await axios.get(`${url}/api/webapp/articles`);
      const resArt = art?.data
        ?.filter((a) => articleIds.includes(a.id))
        .map((v) => ({ ...v, _model: "Article" }));
      setFullFavourite((v) => [...(v || []), ...(resArt || [])]);
      const vid = await axios.get(`${url}/api/webapp/articles`);

      const resVid = vid?.data
        ?.filter((a) => videosIds.includes(a.id))
        .map((v) => ({ ...v, _model: "Video" }));
      setFullFavourite((v) => [...(v || []), ...(resVid || [])]);

      const podc = await axios.get(`${url}/api/webapp/articles`);

      const resPodc = podc?.data
        ?.filter((a) => podcastIds.includes(a.id))
        .map((v) => ({ ...v, _model: "Podcast" }));
      setFullFavourite((v) => [...(v || []), ...(resPodc || [])]);
    };

    handleReq();
  }, [favourite, url]);

  const handleFavorite = (e, model, id) => {
    e.preventDefault();

    axios
      .delete(
        `${url}/api/favorites?user=${window.Telegram.WebApp.initDataUnsafe.user.id}&_model=${model}&instance_id=${id}`
      )
      .then(() => {
        console.log(id, model, fullFavourite);
        setFullFavourite(
          fullFavourite.filter((g) => g.id === id && g._model === model)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoading || !fullFavourite)
    return <img src={loader} alt="loader" className="loader" />;

  return (
    <section>
      <h2>Избранное</h2>
      <div className="favourite-container">
        {fullFavourite.map((f) => (
          <Link
            to={`${
              f._model === "Article"
                ? "/blog/:"
                : f._model === "Podcast"
                ? "/media/podcastList/:"
                : "/media/videoList/:"
            }${f.id}`}
            className="favourite-item"
            key={f._model + f.id}
          >
            <div className="favourite-item-content">
              <img
                src={f?.content[0] || favouriteImg}
                alt="favourite"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = favouriteImg;
                }}
              />
              <div className="favourite-item-text">
                <p>{f.created_date.slice(0, 10)}</p>
                <h4>{f.header}</h4>
              </div>
            </div>
            <button
              onClick={(e) => handleFavorite(e, f._model, f.id)}
              className="favourite-item-delete"
            >
              <img src={favouriteActiveSvg} alt="" />
            </button>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Favourite;
