import React from "react";
import { Link } from "react-router-dom";
import back from "../../img/back-arrow.svg";

const Cookie = () => {
  return (
    <div>
      <div className="edit-header">
        <Link to="/profile/edit" className="back-button">
          <img src={back} alt="back" />
        </Link>
        <br />
        <br />
      </div>
      <br />
      <b>
        Политика использовании файлов cookie и аналогичных технологий
      </b>
      <br />
      <br />
      Данный канал НЕ предполагает пассивного сбора и использования информации о вас. Мы не
      используем метрики или Cookie файлы в данном Telegram – боте (web-bot).
    </div>
  );
};

export default Cookie;
