import React from "react";
import { Link } from "react-router-dom";
import back from "../../img/back-arrow.svg";

const TermsUseAndPrivacy = () => {
  return (
      <div>
          <div className="edit-header">
        <Link to="/profile/edit" className="back-button">
          <img src={back} alt="back" />
        </Link>
        <br />
        <br />
      </div>
      <br />
      <b>Условия использования и политика конфиденциальности:</b>
      <br />
      <br />
      <b>1. Условия использования</b>
      <br />
      <br />
      Данный web-bot предусмотрен для информационного обслуживания его
      посетителей. Web-bot On&Co предназначен для жителей Российской Федерации,
      являющихся медицинскими или фармацевтическими работниками (специалистами
      системы здравоохранения). Канал не предназначен для лиц моложе 18 лет. Всё
      содержание данного Интернет-сайта является собственностью или
      подконтрольно компании ООО «Джонсон & Джонсон» (далее – Компания «Джонсон
      & Джонсон») и защищено российскими и международными законами об охране
      авторских и смежных прав. Вы можете скачивать содержимое данного канала
      только для собственного, личного некоммерческого использования. При этом
      какое-либо изменение или воспроизведение содержания канала, а равно
      использование информации, размещённой на канале для иных, не
      предусмотренных условиями пользования канала действий, запрещено. В иных,
      прямо не предусмотренных условиями пользования канала случаях, содержимое
      (контент) не может быть использовано или скопировано, как полностью, так и
      в любой части. Содержание канала носит исключительно информационный
      характер. Информационная продукция, представленная в настоящем канале,
      носит справочный характер, не является медицинской консультацией и не
      должна быть использована для принятия решения о лечении пациентов.
      Владельцы данного канала будут прилагать усилия для поддержания
      обновлённой и точной информации, но не будут делать заявлений, гарантий
      или уверений в точности, применимости предоставляемой информации.
      Владельцы данного канала не несут ответственности за любые повреждения или
      ущерб, вызванные посещением или неспособностью посещения канала, а также
      связанные с доверием к информации, представленной на данном Сайте. Для
      пользователей Сайта доступна функция регистрации. Во время регистрации у
      Пользователя могут быть запрошены персональные данные. Регистрация
      пользователя является необходимой частью для доступа к нижеперечисленным
      сервисам web-bot:
      <br />
      <br />
      <b>• Просмотр контента, размещенного на Сайте в любой форме.</b>
      <br />
      <br />
      Данный бот может содержать гиперссылки или ссылки на другие сайты или
      каналы, но владельцы данного канала не несут ответственности за содержимое
      этих сайтов/каналов и не отвечают за ущерб или вред, обусловленный
      опубликованной на них информацией. Все ссылки на другие сайты/каналы
      помещены исключительно для удобства посетителей данного канала. Товарные
      знаки, знаки обслуживания, фирменные наименования, фирменный стиль и
      продукция, указанные на данном канале защищены. Запрещается их
      использование без предварительного письменного разрешения владельцев
      данного канала, за исключением случаев идентификации продукции или услуг
      компании. В отношении персонально идентифицируемой информации,
      передаваемой на этот канал, действует изложенная ниже Политика
      конфиденциальности. Владельцы канала могут в свободном порядке и в любых
      целях использовать или копировать любую иную информацию, передаваемую на
      этот канал любыми способами в электронных коммуникациях, включая любые
      идеи, изобретения, концепции, технологии и ноу-хау. Такие цели могут
      включать в себя разглашение третьим лицам и/или разработку, производство
      и/или выведение на рынок товаров и услуг. К таким целям также могут
      относиться: открытие их третьим лицам и/или разработка, производство и/или
      сбыт продукции или услуг на мировом уровне и без всякой компенсации.
      Отправитель любого сообщения (а равно информации), на этот канал или
      владельцам этого канала лично несёт ответственность за содержание такого
      сообщения (информации), в том числе за её достоверность и точность.
      Одновременно вы подтверждаете готовность осуществить, признать и
      предоставить владельцам канала любые документы, которые могут понадобиться
      или быть полезными при приобретении, завершении, осуществлении или
      установления любых авторских прав или иных прав на интеллектуальную
      собственность. Владелец канала оставляет за собой права удалять, изменять
      или пополнять содержимое канала в любое время по любой причине без
      предупреждения и без объяснения причин указанных действий.
      <br />
      <br />
      <b>2. Политика конфиденциальности</b>
      <br />
      <br />
      Компания «Джонсон & Джонсон» серьёзно относится к вопросам
      конфиденциальности и хотела бы ознакомить вас с тем, как мы собираем,
      используем и раскрываем информацию. Эта Политика конфиденциальности
      описывает наши методы работы с информацией, которую мы (далее - Johnson &
      Johnson), наши контрагенты и партнёры собираем через настоящий Telegram –
      бот (web-bot), управляемый или контролируемый нами, и откуда вы получаете
      доступ к данной Политике конфиденциальности. Предоставляя свою
      персональную информацию либо используя данный канал, вы соглашаетесь с
      условиями и положениями настоящей Политики конфиденциальности.
      <br />
      <br />
      <b>СБОР ИНФОРМАЦИИ</b>
      <br />
      <br />
      <b>ПРЕДОСТАВЛЯЕМАЯ ВАМИ ИНФОРМАЦИЯ</b> Для получения доступа к контенту
      вам необходимо пройти процесс идентификации – предоставить персональную
      информацию для того, чтобы вы могли получить доступ к закрытому контенту
      канала (например, информационным постам, опросам, калькуляторам,
      видеороликам и проч.) или принять участие в конкретном виде деятельности
      (например, опросе). Вас предупредят о том, какая информация является
      обязательной, а какая — нет. Мы можем объединять информацию,
      предоставленную вами, с другой информацией, которую мы собрали о вас
      (онлайн или оффлайн). Мы также можем объединять её с информацией о вас,
      полученной из других источников, например, от других действующих компаний
      группы Johnson & Johnson, общедоступных источников информации (включая
      информацию из ваших общедоступных профилей социальных медиаресурсов) и
      других третьих лиц.
      <br />
      <br />
      <b>ПАССИВНЫЙ СБОР И ИСПОЛЬЗОВАНИЕ ИНФОРМАЦИИ</b>
      <br />
      <br />
      Данный канал НЕ предполагает пассивного сбора и использования информации о
      вас. Мы не используем метрики или Cookie файлы в данном Telegram – боте
      (web-bot).
      <br />
      <br />
      <b>Как мы используем и раскрываем информацию</b>
      <br />
      <br />
      Мы используем и раскрываем информацию, предоставленную вами нам (нашим
      контрагентам), как это указано в момент её сбора. Мы также используем
      информацию от вас или о вас: для ответа на ваши вопросы и выполнения ваших
      запросов, например, отправки запрашиваемых вами документов или
      предупреждений по электронной почте; для отправки вам важной информации о
      наших отношениях с вами или о канале, включая изменения наших условий,
      положений и политик и (или) прочую информацию административного характера;
      в бизнес-целях, например, для анализа данных, аудиторской проверки,
      разработки новых продуктов, расширения функций канала, усовершенствования
      наших продуктов и услуг, идентификации тенденций использования данного
      канала. Компания «Джонсон & Джонсон» является стороной, ответственной за
      управление совместно используемой персональной информацией. Информация
      также может быть раскрыта нашим сторонним партнёрам, совместно с которыми
      мы продвигаем товары на рынок; нашим сторонним контрагентам, поставщикам
      таких услуг, как например, хостинг и администрирование веб-сайта, хостинг
      мобильных приложений, анализ данных, обработка платежей, выполнение
      заказов, обеспечение инфраструктуры, ИТ-услуги, обслуживание клиентов,
      доставляемые по электронной и традиционной почте услуги, обработка
      кредитных карт, услуги аудита и другие услуги, чтобы позволить им
      предоставлять свои услуги; третьей стороне в случае реорганизации,
      слияния, продажи, создания совместного предприятия, передачи или
      ликвидации компании, активов или акций полностью или частично (включая всё
      вышеперечисленное в связи с банкротством или судебным процессом). Кроме
      того, мы используем и раскрываем информацию, собранную через Telegram -
      канал, которая, как мы считаем, необходима или уместна: (a) в соответствии
      с применимым законодательством, включая законы, действующие за пределами
      вашей страны проживания; (б) в целях содействия судебному процессу; (в) в
      качестве ответа на запросы государственных органов власти, включая
      государственные органы власти за пределами вашей страны проживания; (г) в
      целях принудительного применения наших условий и положений; (д) в целях
      защиты нашей деятельности и деятельности наших аффилированных организаций;
      (е) в целях защиты наших и ваших прав, конфиденциальности, безопасности
      или имущества и (или) прав, конфиденциальности, безопасности или имущества
      наших аффилированных лиц и других лиц; (ж) чтобы позволить нам
      воспользоваться доступными средствами правовой защиты или ограничить объём
      ущерба, который мы, как и любые компании группы «Johnson & Johnson» можем
      понести. Мы также можем использовать и раскрывать информацию, собранную
      через канал другими способами, с вашего согласия. Кроме того, мы можем
      использовать и раскрывать информацию, которая хранится в форме, не
      позволяющей установить личность в каких-либо целях. Если мы объединяем
      информацию, которая хранится в форме, не позволяющей установить личность,
      с информацией, которая хранится в форме, позволяющей установить личность
      (например, объединение вашего имени с вашим географическим
      местоположением), то мы будем обращаться с объединённой информацией как с
      персональной информацией в течение всего времени, пока она будет
      оставаться объединённой.
      <br />
      <br />
      <b>САЙТЫ И УСЛУГИ ТРЕТЬИХ ЛИЦ </b>
      <br />
      <br />
      Эта Политика конфиденциальности не относится к конфиденциальности,
      информации и другим аспектам любых третьих лиц, включая третьи лица,
      управляющие любым сайтом или веб-объектом (включая, помимо прочего, любое
      приложение), доступное через этот Telegram - бот (web-bot) или на которое
      данный Telegram – бот (webbot) содержит ссылку. Мы также не несём
      ответственности за конфиденциальность, информацию и другие аспекты любых
      третьих лиц. Наличие или включение ссылки на любой такой сайт или объект
      на Сайте не подразумевает подтверждение с нашей стороны или со стороны
      наших аффилированных лиц.
      <br />
      <br />
      <b>БЕЗОПАСНОСТЬ</b>
      <br />
      <br />
      Мы используем разумные и целесообразные организационные, технические и
      административные меры для защиты персональной информации, находящейся в
      нашем распоряжении. Тем не менее, ни одна система хранения или передачи
      данных через Интернет не может гарантировать полную безопасность. Если у
      вас есть основания полагать, что ваш обмен информацией с нами не является
      безопасным (например, если вам кажется, что безопасность какой-либо вашей
      учетной записи у нас подвергается опасности), пожалуйста, известите нас об
      этом немедленно согласно разделу «Как с нами связаться» ниже.
      <br />
      <br />
      <b>ВЫБОР И ДОСТУП</b>
      <br />
      <br />
      Мы не раскрываем ваши персональные данные, которые получены посредством
      данного Telegram-бота (Web-bot). Если вы не хотите больше получать
      информационных сообщений при помощи данного Telegram - бота, то вам
      необходимо покинуть данный Telegram - бот. После того, как вы покинули
      данный Telegram - бот, вам не будут приходить информационные сообщения с
      данного Telegram - бота.
      <br />
      <br />
      <b>КАК ПОСМОТРЕТЬ, ИЗМЕНИТЬ ИЛИ УДАЛИТЬ СВОЮ ПЕРСОНАЛЬНУЮ ИНФОРМАЦИЮ</b>
      <br />
      <br />
      Если вы хотите просмотреть, откорректировать, изменить или удалить
      персональную информацию, которую вы предоставили через данный канал,
      свяжитесь с нами по телефону или направьте запрос по адресу, указанному
      ниже в разделе «Как с нами связаться». Мы постараемся выполнить ваше
      требование как можно скорее.
      <br />
      <br />
      <b>СРОК ХРАНЕНИЯ ДАННЫХ </b>
      <br />
      <br />
      Мы храним вашу персональную информацию в течение периода времени,
      необходимого для целей, указанных в данной Политике конфиденциальности, но
      не менее 10 лет, если только более длительный срок хранения не является
      необходимым или не разрешается действующим законодательством.
      <br />
      <br />
      <b>ИСПОЛЬЗОВАНИЕ САЙТА НЕСОВЕРШЕННОЛЕТНИМИ</b>
      <br />
      <br />
      Канал не нацелен на лиц в возрасте младше 18 лет, и мы требуем, чтобы
      такие лица не предоставляли персональную информацию через Сайт.
      <br />
      <br />
      <b>ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ДАННЫХ </b>
      <br />
      <br />
      Ваша персональная информация может храниться и обрабатываться в стране,
      где у нас есть объекты или поставщики услуг, и, используя наш канал или
      предоставляя своё согласие нам (где это требуется по закону), вы даёте
      согласие на передачу информации за пределы вашей страны проживания в
      другую страну (трансграничную передачу), включая Соединённые Штаты
      Америки, где могут существовать другие законы о защите информации,
      отличающиеся от законов вашей страны.
      <br />
      <br />
      <b>СПЕЦИАЛЬНЫЕ КАТЕГОРИИ ДАННЫХ </b>
      <br />
      <br />
      Данный канал не предполагает сбор специальных или биометрических
      персональных данных.
      <br />
      <br />
      <b>ИЗМЕНЕНИЯ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ </b>
      <br />
      <br />
      Мы можем изменить данную Политику конфиденциальности. Чтобы узнать о дате
      последнего пересмотра настоящей Политики конфиденциальности, смотрите
      указание «Последнее обновление». Любые изменения данной Политики
      конфиденциальности вступают в силу с момента публикации новой версии
      Политики конфиденциальности на Сайте. Использование вами Сайта после
      внесения изменений означает, что вы принимаете пересмотренную Политику
      конфиденциальности.
      <br />
      <br />
      <b>КАК С НАМИ СВЯЗАТЬСЯ</b>
      <br />
      <br />
      Если у вас есть какие-либо вопросы о данной Политике конфиденциальности,
      свяжитесь с с нами: Контактная информация: ООО «Джонсон & Джонсон» Россия
      (Подразделение фармацевтических товаров «Джонсон & Джонсон»). Россия, Москва, ул
      Крылатская, д.17к3 Тел. +7 495 755 83 57 Email: janreception@its.jnj.com
      <br />
      <br />
      <b>ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ 06.07.2023 </b>
      <br />
      <br />
      CP-401386 | июль 2023
    </div>
  );
};

export default TermsUseAndPrivacy;
